.no-transition {
  transition: none !important;
}
.display-flex {
  display: flex;
}
html {
  /*margin: @main-menu-width*/
}
.staticWidthSample {
  min-width: 300px;
}
